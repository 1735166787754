import { useState } from "react";

import parse from "html-react-parser";

const FAQContents = ({ data }) => {
    const [currentId,setCurrentId]=useState(0);
    const [initial,setInitial]=useState(true);

    return (
        
        <>
        {console.log(data , data.slice(0,4))
        }
            {data.slice(0,4).map((faqs,i) => (
                <div className={`faq_sectionAccordion sectionAccordion-wrapper ${(currentId === faqs.id || (initial && i===0)) && 'expanded'}`} onClick={()=>{
                    setInitial(false)    
                    setCurrentId(faqs.id)
                }}>
                    <button
                        className="faq_sectionAccordion-trigger sectionAccordion-trigger"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                    >
                        <span className="question">{faqs.title}</span>
                        <span className={`faq_sectionAccordion-trigger_icon sectionAccordion-trigger_icon ${(currentId === faqs.id || (initial && i===0))? 'icon-minus' : 'icon-plus'}`}></span>
                    </button>
                    <div id="collapseOne" className={`faq_sectionAccordion-content sectionAccordion-content ${(currentId === faqs.id || (initial && i===0)) ? 'show' : 'collapse'}`}>
                        <p className="text">
                            {faqs.answer ? parse(faqs.answer) : "The admin have not answered this question yet"}
                        </p>
                    </div>
                </div>
            ))}
        </>
    )
}
export default FAQContents;