

import ReviewSlider from "../../../SliderComponents/ReviewSlider/reviewSlider";

import "./feedbackSection.css";

const FeedbackSection = () => {
    
    return (
        <section className="feedback section">
            <div className="container displayFlex flex-wrap flex-lg-row-reverse align-items-start justifyContentBetween justify-content-md-center justify-content-lg-between">
                <div className="feedback_header section_header col-12 col-lg-5">
                    <span className="lineTitle">Feedback</span>
                    <h2 className="title">
                        What
                        <span className="ternary">Our Clients</span>
                        Say About Us
                    </h2>
                    <p className="text">
                        These are some of the feedbacks that we manually collected from our clients. We are always happy to recieve your feedbacks that will help us update and upgarde more in our services.
                    </p>
                    <a className="btn" href="/faq">Consult now</a>
                </div>
                <div className="wrapper col-lg-6 col-12">
                    <ul className="feedback_slider displayFlex flex-wrap" style={{marginLeft:-30,marginRight:30}}>
                        <ReviewSlider/>
                    </ul>
                </div>
            </div> 
        </section>
    )
}
export default FeedbackSection;