import { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useEffect } from "react";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const InputSection = ({faqAnswer,setFaqAnswer,faqName, setFaqName, faqEmail, setFaqEmail, faqMessage,setFaqMessage,faqPhone,setFaqPhone, faqTitle,setFaqTitle, updateArray, currentChangesArray, setCurrentChangesArray, imageArrayChanged, setImageArrayChanged, selectedImageArrayNames, imageChanged, setImageChanged, totalImageInputs, setUpdateArray, setTotalImageInputs, selectedImageArray, setSelectedImageArray, selectedTitle, setSelectedTitle, selectedId, selectedImage, selectedArticle, setSelectedArticle, addElement, setSelectedImage, updateSelected, discardInputs, plusClicked, deleteSelected, location, setLocation, isService, isProject, collectionName, selectedInitialPara, setSelectedInitialPara, setLoading, setUpdateImage }) => {

    //setImageArray is the array of image files
    //imageArrayChanged = list of bools
    //currentImageArray = lise of key vals

    const [image, setImage] = useState("");
    useEffect(()=>{
        console.log(selectedArticle);
    },[selectedArticle])

    useEffect(() => {
        if (!plusClicked) { 
            setUpdateArray(true);
            if (imageArrayChanged.length < totalImageInputs) {
                for (let i = imageArrayChanged.length; i < totalImageInputs; i++) {
                    setImageArrayChanged([...imageArrayChanged, false]);
                }
            }
            else if (imageArrayChanged.length > totalImageInputs) {
                const newArray = imageArrayChanged.slice(0, totalImageInputs);
                setImageArrayChanged(newArray);
            }
        }
        const newArray = selectedImageArray.slice(0, totalImageInputs);
        setSelectedImageArray(newArray);
    }, [totalImageInputs])

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageChanged(true);
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    const handleImageChange = (event, index) => {
        if (plusClicked) {
            const files = event.target.files;
            const imagesArray = [];

            // Convert the FileList object to an array
            for (let i = 0; i < files.length; i++) {
                imagesArray.push(files[i]);
            }

            setSelectedImageArray([...selectedImageArray, ...imagesArray]);
        }
        else {
            const files = event.target.files;
            setSelectedImageArray([...selectedImageArray, { index: index, file: files[0] }]);
        }
    };


    return (
        <form>
            {collectionName === "FAQs"? <h5> Question title </h5> : <h5> Title/Reviewer name : </h5>}

            {collectionName==="FAQs" ? (<input value={faqTitle} className="titleInput-dashboard"  type="text" onChange={(e)=>setFaqTitle(e.target.value)}/>) :( <input value={selectedTitle} placeholder="Enter Title" type="text" className="titleInput-dashboard" onChange={(e) => setSelectedTitle(e.target.value)} />)}



            {collectionName === "Products" && (
                <>
                    <h5> Number of additional images : </h5>
                    <input type="number" className="titleInput-dashboard" value={totalImageInputs} onChange={(e) => {
                        if (e.target.value >= 1) setTotalImageInputs(e.target.value)
                    }} />
                </>
            )}

            {collectionName !== "FAQs" && (
                <>
                    <h5> Main Image: </h5>
                    <div style={imageChanged ? { backgroundImage: `url(${image})` } : selectedId === 0 ? { backgroundImage: "url('/Images/loginBackground.jpeg')" } : { backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${selectedImage}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`, backgroundSize: "cover" }} onChange={(e) => {
                        setUpdateImage(true);
                        setSelectedImage(e.target.files[0]);
                    }}>


                        <label className="fileType">
                            <div className="fileUpload">
                                <AiOutlinePlusCircle className="plusIcon" />
                            </div>
                            <input accept="image/*" type="file" className="noBorder" onChange={onImageChange} required />
                        </label>
                    </div>
                </>
            )}
            {(collectionName === "Products") && Array.from({ length: totalImageInputs }).map((indx, i) => (
                <>
                    <h5> Additional image {i + 1} : </h5>
                    <div style={(imageArrayChanged[i] && updateArray) ? { backgroundImage: `url(${currentChangesArray.find(obj => obj.id === i)?.url})` } : selectedId === 0 ? { backgroundImage: "url('/Images/loginBackground.jpeg')" } : { backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${selectedImageArrayNames[i] ? selectedImageArrayNames[i] : selectedImage}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`, backgroundSize: "cover" }} onChange={(e) => {
                        const newArray = [...imageArrayChanged];
                        newArray[i] = true;
                        setImageArrayChanged(newArray);

                        const url = URL.createObjectURL(e.target.files[0]);
                        const updatedArray = currentChangesArray.map((obj) =>
                            obj.id === i ? { ...obj, url } : obj
                        );
                        const newArrayObj = updatedArray.find((obj) => obj.id === i);
                        if (!newArrayObj) {
                            updatedArray.push({ id: i, url });
                        }
                        setCurrentChangesArray(updatedArray);

                        setUpdateArray(true);
                        handleImageChange(e, i);
                    }}>


                        <label className="fileType">
                            <div className="fileUpload">
                                <AiOutlinePlusCircle className="plusIcon" />
                            </div>
                            <input accept="image/*" type="file" className="noBorder" />
                        </label>
                    </div>
                </>
            ))}

            {collectionName === "FAQs" ? <h5> Answer </h5> : <h5> Review/Article/Description: </h5>}
            {collectionName === "FAQs" ? (<ReactQuill theme="snow" value={faqAnswer} onChange={setFaqAnswer} style={{ height: 160, marginBottom: 80 }}/>) : ( 
            <ReactQuill theme="snow" value={selectedArticle} onChange={setSelectedArticle} style={{ height: 160, marginBottom: 80 }} />)}

            {isProject && (
                <>
                    <h5> Location: </h5>
                    <input type="text" placeholder="Location" value={location} className="durationInput-dashboard" onChange={(e) => setLocation(e.target.value)} style={{ marginRight: 10 }} />
                </>
            )}

            {collectionName === "Review" && (
                <>
                    <h5> Reviewer status: </h5>
                    <input className="titleInput-dashboard" type="text" value={selectedInitialPara} placeholder="Enter status" onChange={(e) => setSelectedInitialPara(e.target.value)} />
                </>
            )}
            {collectionName !== "Review" && (
                <>
                {collectionName === "FAQs" ? (
                    <>
                        <h5> Message </h5>
                    <textarea className="textInput-dashboard" type="text" value={faqMessage} placeholder="Initial paragraph" onChange={(e) => setFaqMessage(e.target.value)} />
                
                    </>
                ) : (
                <>
                
                    <h5> Initial paragraph : </h5>
                    <textarea className="textInput-dashboard" type="text" value={selectedInitialPara} placeholder="Initial paragraph" onChange={(e) => setSelectedInitialPara(e.target.value)} />
                </>
                )}
                </>
                )}

                {collectionName === "FAQs" && (
                    <>
                        <h5> Name : </h5>
                        <input className="titleInput-dashboard" type = "text" value={faqName} onChange={(e)=>setFaqName(e.target.value)}/>
                        <h5> Email : </h5>
                        <input className="titleInput-dashboard" value={faqEmail} onChange={(e)=>setFaqEmail(e.target.value)} type = "text"/>
                        <h5> Phone : </h5>
                        <input className="titleInput-dashboard" value={faqPhone} onChange={(e)=>setFaqPhone(e.target.value)} type = "text"/>
                    </>
                )}

            <div className="buttonFlex-dashboard">
                <button className="btn btn-warning" onClick={(e) => {
                    e.preventDefault();
                    {/**
                 * 
                 * selectedTitle
                 * selectedImage
                 * imageArray
                 * selectedArticle
                 * oldPrice
                 * newPrice
                 * location
                 * selectedInitialPara
                 * 
                 */}

                    if (selectedTitle === " " && collectionName!=="FAQs") return alert("Title cannot be left empty");
                    else if (selectedImage === " "&& collectionName!=="FAQs") return alert("Image cannot be left empty");
                    else if (selectedArticle === " "&& collectionName!=="FAQs") return alert("Article cannot be left empty");
                    else if (selectedInitialPara === " "&& collectionName!=="FAQs") return alert("Initial parameter cannot be left empty");


                    else {
                        if (selectedId === 0) {

                            if (isService) {
                                setLoading(true);
                                addElement(e, true, false, false,false);
                            }
                            else if (isProject) {
                                if (location === " ") return alert("Location cannot be left empty");
                                else {
                                    setLoading(true);
                                    addElement(e, false, true, false,false);
                                }
                            }
                            else if (collectionName === "Products") {
                                if (selectedImageArray.length !== parseInt(totalImageInputs)) {
                                    console.log(selectedImageArray.length);
                                    console.log(typeof selectedImageArray);
                                    console.log(totalImageInputs);
                                    console.log(typeof totalImageInputs)
                                    return alert("Image uploading error, process haulted");
                                }
                                else {
                                    setLoading(true);
                                    addElement(e, false, false, true,false);
                                }
                            }
                            else if(collectionName==="FAQs"){
                                if(faqTitle === " ")return alert ("Title cannot be left empty");
                                if(faqAnswer === "")return alert ("Answer is undefined");
                                if(faqEmail === " ") return alert ("Faq email cannot be left empty");
                                if(faqName === " ")return alert ("FAQ name cannot be left emtpy");
                                if(faqMessage === " ") return alert ("FAQ message cannot be left empty");
                                if(faqPhone === " ")return alert ("FAQ phone cannot be left empty");
                                setLoading(true);
                                addElement(e,false,false,false,true);
                            }
                            else {
                                setLoading(true);
                                addElement(e, false, false, false,false);
                            }

                        }

                        else {
                            if (isService) {
                                setLoading(true);
                                updateSelected(e, true, false, false,false);
                            }
                            else if (isProject) {
                                if (location === " ") return alert("Location cannot be left empty");
                                else {
                                    setLoading(true);
                                    updateSelected(e, false, true, false,false);
                                }
                            }
                            else if (collectionName === "Products") {
                                
                                
                                setLoading(true);
                                updateSelected(e, false, false, true,false);
                                

                            }
                            else if(collectionName === "FAQs"){

                                if(faqTitle === " ")return alert ("Title cannot be left empty");
                                if(faqAnswer === "")return alert ("Answer is undefined");
                                if(faqEmail === " ") return alert ("Faq email cannot be left empty");
                                if(faqName === " ")return alert ("FAQ name cannot be left emtpy");
                                if(faqMessage === " ") return alert ("FAQ message cannot be left empty");
                                if(faqPhone === " ")return alert ("FAQ phone cannot be left empty");

                                setLoading(true);
                                updateSelected(e,false,false,false,true);
                            }
                            else {
                                setLoading(true);

                                updateSelected(e, false, false, false,false);
                            }
                        }

                    }
                }}> Commit </button>
                <button className="btn btn-warning" type="reset" onClick={() => discardInputs()}> Discard </button>
                <button className={`btn btn-warning deleteButton-dashboard ${plusClicked && 'buttonUnclickable'}`} onClick={(e) => {


                    setLoading(true);
                    deleteSelected(e)
                }}> DELETE </button>
            </div>
        </form >
    )
}
export default InputSection;