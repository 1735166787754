import ProgressBar from "@ramonak/react-progress-bar";
import "./progressBarCollection.css";

const ProgressBarCollection=()=>{
    return (
        <>
            <div className="progressBarContainer">
                <h5> Renovation </h5> 
                <ProgressBar completed={90} bgColor="#ffc631"/>
            </div>
            <div className="progressBarContainer">
                <h5> Residence </h5> 
                <ProgressBar completed={96} bgColor="#0da574"/>
            </div>
            <div className="progressBarContainer">
                <h5> Commercial </h5> 
                <ProgressBar completed={89} bgColor="#ffc631"/>
            </div>
            <div className="progressBarContainer">
                <h5> Institutional </h5> 
                <ProgressBar completed={99} bgColor="#0da574"/>
            </div>
        </>
    )
}
export default ProgressBarCollection;