import { useState, useEffect } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../ConfigurationSystem/firebase";
import { storage } from "../../../../ConfigurationSystem/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { HiBarsArrowDown } from "react-icons/hi2";

import AddElementIcon from "../AddElementIcon/addElementIcon";
import InputSection from "../InputSection/inputSection";
import SpinnerSvg from "../../../../ScreenLoader/screenLoader";

import "./structureFormat.css";
import DataDisplayer from "./StructureFormatComponents/dataDisplayer";


const StructureFormat = ({ title, collectionName }) => {
    const [data, setData] = useState([]);

    const [clicked, setClicked] = useState(false);
    const [plusClicked, setPlusClicked] = useState(true);
    const [updateImage, setUpdateImage]=useState(false);
    const [updateArray, setUpdateArray]=useState(false);

    //only for projects
    const [location, setLocation] = useState(" ");
    const collectionRef = collection(db, collectionName);

    //only for products
    const [newPrice,setNewPrice]=useState(0);
    const [oldPrice,setOldPrice]=useState(0);
    const [totalStars,setTotalStars]=useState(0);
    const [selectedImageArray,setSelectedImageArray]=useState([]);
    const [totalImageInputs,setTotalImageInputs]=useState(1);
    const [updateImageFiles,setUpdateImageFiles]=useState([]);
    const [selectedImageArrayNames,setSelectedImageArrayNames]=useState("");
    const [currentChangesArray,setCurrentChangesArray]=useState([{}]);
    const [imageArrayChanged,setImageArrayChanged]=useState([]);

    //only for FAQs
    const [faqEmail,setFaqEmail]=useState(" ");
    const [faqMessage,setFaqMessage]=useState(" ");
    const [faqName,setFaqName]=useState(" ");
    const [faqPhone,setFaqPhone]=useState(0);
    const [faqTitle,setFaqTitle]=useState(" ");
    const [faqAnswer,setFaqAnswer]=useState(" ");



    const [selectedId, setSelectedId] = useState(0);
    const [selectedTitle, setSelectedTitle] = useState(" ");
    const [selectedImage, setSelectedImage] = useState(" ");
    const [selectedInitialPara,setSelectedInitialPara]=useState(" ");
    const [selectedArticle, setSelectedArticle] = useState(" ");
    const [loading, setLoading] = useState(true);
    const [imageChanged,setImageChanged]=useState(false);



    const getData = async () => {
        const data = await getDocs(collectionRef);
        await setData(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
        await setLoading(false);
    }


    useEffect(() => {
        getData();
    }, [])

    const handleUpload = async (id) => {

        let contentTypeValue="image/webp";
        
        const storageRef = ref(storage, `${collectionName}Covers/${selectedImage.name.replace(/ /g, "-")}`);
        
        if(selectedImage.type === "image/webp") contentTypeValue = "image/webp";
        if(selectedImage.type === "image/jpg") contentTypeValue = "image/jpg";
        if(selectedImage.type === "image/png") contentTypeValue = "image/png";
        
        
        await uploadBytes(storageRef, selectedImage, {
            contentType: contentTypeValue,
            customMetadata: {
                owner: id
            }
        }); 
    }

    const handleMultipleUploads=async (id,image)=>{
        let contentTypeValue="image/webp";

        const storageRef = ref(storage, `${collectionName}Covers/${image.name.replace(/ /g, "-")}`);
        
        if(image.type === "image/webp") contentTypeValue = "image/webp";
        if(image.type === "image/jpg") contentTypeValue = "image/jpg";
        if(image.type === "image/png") contentTypeValue = "image/png";
        
        
        await uploadBytes(storageRef, image, {
            contentType: contentTypeValue,
            customMetadata: {
                owner: id
            }
        });
    }




    const addElement = async (e, isService, isProject, isProduct, isFaq) => {
        e.preventDefault();
        const docRef = doc(collectionRef);
        if (isService) {
            await setDoc(docRef, { Title: selectedTitle, MiniStart:selectedInitialPara, Image: selectedImage.name.replace(/ /g, "-"), Article: selectedArticle, createdAt: new Date() });
        }
        else if (isProject) {
            await setDoc(docRef, { Title: selectedTitle, MiniStart:selectedInitialPara, Image: selectedImage.name.replace(/ /g, "-"), Article: selectedArticle, location: location, createdAt: new Date() })
        }


        else if (isProduct){
            selectedImageArray.map(async image=> await handleMultipleUploads(docRef.id , image));
            const imageArrayNames=selectedImageArray.map(indx=>indx.name.replace(/ /g, "-"));
            
            await setDoc(docRef, { Title: selectedTitle, MiniStart:selectedInitialPara, Image: selectedImage.name.replace(/ /g, "-"), Article: selectedArticle, TotalStars:totalStars, ImageArray:imageArrayNames, TotalImageInputs:totalImageInputs, createdAt: new Date()});
        }
        else if(isFaq){
            await setDoc(docRef, { email:faqEmail, message:faqMessage, name:faqName, phone:faqPhone, title:faqTitle, answer:faqAnswer});
        }

        else {
            await setDoc(docRef, { Title: selectedTitle, MiniStart:selectedInitialPara, Image: selectedImage.name.replace(/ /g, "-"), Article: selectedArticle, createdAt: new Date() });
        }
        if(!isFaq) await handleUpload(docRef.id);
        getData();
    }





    const updateSelected = async (e, isService, isProject, isProduct, isFaq) => {
        e.preventDefault();
        const docRef = doc(collectionRef);
        const Doc = doc(db, collectionName, selectedId);
        
        if (isService) {
            if(updateImage) await updateDoc(Doc, { Article: selectedArticle, Image: selectedImage.name, Title: selectedTitle, MiniStart:selectedInitialPara ,createdAt: new Date() });
            else await updateDoc(Doc, { Article: selectedArticle, Title: selectedTitle, MiniStart:selectedInitialPara ,createdAt: new Date() });
        }
        else if (isProject) {
            if(updateImage)await updateDoc(Doc, { Article: selectedArticle, Image: selectedImage.name, Title: selectedTitle, MiniStart:selectedInitialPara, location: location, createdAt: new Date() });
            else await updateDoc(Doc, { Article: selectedArticle, Title: selectedTitle, MiniStart:selectedInitialPara, location: location, createdAt: new Date() });
            
        }
        else if(isProduct){
            if(updateArray){
                selectedImageArray.map(async image=> await handleMultipleUploads(docRef.id , image.file));
                
                for (const item of selectedImageArray) {
                    const { index, file } = item;
                    if (index < selectedImageArrayNames.length) {
                      selectedImageArrayNames[index] = file.name.replace(/ /g, "-");
                    }
                    else selectedImageArrayNames.push(file.name.replace(/ /g, "-"));
                }

                
                if(selectedImageArrayNames.length > totalImageInputs){
                    const newArray=selectedImageArrayNames.slice(0,totalImageInputs);
                    setSelectedImageArrayNames(newArray);
                }
                await updateDoc(Doc, {ImageArray:selectedImageArrayNames});
            }

            if(updateImage) if(selectedImage.name) await updateDoc(Doc, { Image: selectedImage.name});
            
            await updateDoc(Doc, { Article: selectedArticle, Title: selectedTitle, MiniStart:selectedInitialPara, TotalStars:totalStars, TotalImageInputs:totalImageInputs,createdAt: new Date() });
        }
        else if(isFaq){
            await updateDoc(Doc, { email:faqEmail, message:faqMessage, name:faqName, phone:faqPhone, title:faqTitle, answer:faqAnswer});
        }
        else {
            if(updateImage) await updateDoc(Doc, { Article: selectedArticle, Image: selectedImage.name, Title: selectedTitle, MiniStart:selectedInitialPara, createdAt: new Date() });
            else await updateDoc(Doc, { Article: selectedArticle, Title: selectedTitle, MiniStart:selectedInitialPara, createdAt: new Date() });
        }
        if(updateImage && !isFaq) await handleUpload(docRef.id);
        getData();
    }

    const deleteSelected = async (e) => {
        e.preventDefault();
        const Doc = doc(db, collectionName, selectedId);
        await deleteDoc(Doc, { Article: selectedArticle, Image: selectedImage, Title: selectedTitle });
        getData();
    }

    const discardInputs = () => {
        setSelectedTitle(" ");
        setSelectedImage(" ");
        setSelectedArticle(" ");
        setLocation(" ");
        setSelectedInitialPara(" ");
    }

    if (loading) return <SpinnerSvg />
    else {
        return (
            <div className="container-fluid structure-format">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-12 sideNav-dashboard cream-dashboard">
                        <h3> {`${title} List`} </h3>

                        <DataDisplayer setFaqAnswer={setFaqAnswer} setFaqName={setFaqName} setFaqEmail={setFaqEmail} setFaqMessage={setFaqMessage} setFaqPhone={setFaqPhone} setFaqTitle={setFaqTitle} setCurrentChangesArray={setCurrentChangesArray}  setImageArrayChanged={setImageArrayChanged} setUpdateImage={setUpdateImage} setUpdateArray={setUpdateArray} setSelectedImageArray={setSelectedImageArray} setImageChanged={setImageChanged} setSelectedImageArrayName={setSelectedImageArrayNames} setTotalImageInputs={setTotalImageInputs} setNewPrice={setNewPrice} setOldPrice={setOldPrice} setTotalStars={setTotalStars} data={data} selectedId={selectedId} setSelectedId={setSelectedId} setSelectedImage={setSelectedImage} setSelectedArticle={setSelectedArticle} setSelectedTitle={setSelectedTitle} setSelectedInitialPara={setSelectedInitialPara} setPlusClicked={setPlusClicked} collectionName={collectionName}/>

                    </div>
                    <div className="sideNavSml-dashboard cream-dashboard">

                        <HiBarsArrowDown className="icon" onClick={() => setClicked(!clicked)} />
                        <div className={`contentSml-dashboard ${clicked ? 'display-dashboard' : 'noDisplay-dashboard'}`}>

                            <DataDisplayer setFaqAnswer={setFaqAnswer} setFaqName={setFaqName} setFaqEmail={setFaqEmail} setFaqMessage={setFaqMessage} setFaqPhone={setFaqPhone} setFaqTitle={setFaqTitle} setCurrentChangesArray={setCurrentChangesArray} setImageArrayChanged={setImageArrayChanged} setUpdateImage={setUpdateImage} setUpdateArray={setUpdateArray} setImageChanged={setImageChanged} setSelectedImageArray={setSelectedImageArray} setSelectedImageArrayName={setSelectedImageArrayNames} setTotalImageInputs={setTotalImageInputs} data={data} setSelectedId={setSelectedId} setSelectedImage={setSelectedImage} setSelectedArticle={setSelectedArticle} setSelectedTitle={setSelectedTitle} setSelectedInitialPara={setSelectedInitialPara} setPlusClicked={setPlusClicked} setNewPrice={setNewPrice} setOldPrice={setOldPrice} setTotalStars={setTotalStars}/>

                        </div>
                    </div>
                    <div className="col-lg-={faqName}9 col-md-9 col-12 selected-display-dashboard">
                        
                        <InputSection faqAnswer={faqAnswer} setFaqAnswer={setFaqAnswer} faqName={faqName} setFaqName={setFaqName} faqEmail={faqEmail} setFaqEmail={setFaqEmail} faqMessage={faqMessage} setFaqMessage={setFaqMessage} faqPhone={faqPhone} setFaqPhone={setFaqPhone} faqTitle={faqTitle} setFaqTitle={setFaqTitle} updateArray={updateArray} currentChangesArray={currentChangesArray} setCurrentChangesArray={setCurrentChangesArray} imageArrayChanged={imageArrayChanged} setImageArrayChanged={setImageArrayChanged}  selectedImageArrayNames={selectedImageArrayNames} updateImageFiles={updateImageFiles} imageChanged={imageChanged} setImageChanged={setImageChanged} setUpdateImageFiles={setUpdateImageFiles} setUpdateArray={setUpdateArray} totalImageInputs={totalImageInputs} setTotalImageInputs={setTotalImageInputs} selectedImageArray={selectedImageArray} setSelectedImageArray={setSelectedImageArray} newPrice={newPrice} oldPrice={oldPrice} totalStars={totalStars} setNewPrice={setNewPrice} setOldPrice={setOldPrice} setTotalStars={setTotalStars} setUpdateImage={setUpdateImage} selectedTitle={selectedTitle} setSelectedTitle={setSelectedTitle} selectedInitialPara={selectedInitialPara} 
                        setSelectedImage={setSelectedImage} setSelectedInitialPara={setSelectedInitialPara} selectedId={selectedId} selectedImage={selectedImage} selectedArticle={selectedArticle} setSelectedArticle={setSelectedArticle} addElement={addElement} updateSelected={updateSelected} discardInputs={discardInputs} plusClicked={plusClicked} deleteSelected={deleteSelected} location={location} setLocation={setLocation} 
                          isService={(collectionName === "Services") ? true : false} collectionName={collectionName} isProject={(collectionName === "Projects") ? true : false} setLoading={setLoading}/>

                        <AddElementIcon setFaqAnswer={setFaqAnswer} setFaqName={setFaqName} setFaqEmail={setFaqEmail} setFaqMessage={setFaqMessage} setFaqPhone={setFaqPhone} setFaqTitle={setFaqTitle} setImageChanged={setImageChanged} setSelectedImageArray={setSelectedImageArray} setTotalImageInputs={setTotalImageInputs} selectedTitle={selectedTitle} setSelectedId={setSelectedId} selectedInitialPara={selectedInitialPara} setSelectedInitialPara={setSelectedInitialPara} setSelectedImage={setSelectedImage} setSelectedTitle={setSelectedTitle} setSelectedArticle={setSelectedArticle} plusClicked={plusClicked} setPlusClicked={setPlusClicked} setLocation={setLocation}/>

                    </div>
                </div>
            </div>
        )
    }
}
export default StructureFormat;