import { useEffect , useState } from "react";
import {MdTempleBuddhist , MdOutlineViewSidebar} from "react-icons/md";
import {BsHouseCheck , BsMic, BsFillBoxFill} from "react-icons/bs";
import {SiYoutubegaming} from "react-icons/si";
import {BsShopWindow} from "react-icons/bs";
import {HiBuildingLibrary} from "react-icons/hi2";
import {GiGate} from "react-icons/gi";
import { Link } from "react-router-dom";
import { getDocs , collection } from "firebase/firestore";
import { db } from "../../../ConfigurationSystem/firebase";

import SpinnerSvg from "../../../ScreenLoader/screenLoader";

import Aos from "aos";
import "aos/dist/aos.css";
import "./partnerSection.css";


const PartnerSection = () => {
    const collectionRef = collection(db, "Products");
    const [products,setProducts]=useState([]);
    const [loading,setLoading]=useState(false);

    useEffect(()=>{
        Aos.init({duration:800,once:true})

        const dataFetchers=async ()=>{
            const data=await getDocs(collectionRef);
            await setProducts(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await setLoading(false);
        }
        dataFetchers();
    },[]);

    if(loading) return <SpinnerSvg/>
    else{
    return (
        <section className="partners section">
            <div className="container">
                <div className="row g-0">
                    <div className="partners_header section_header col-md-6 col-lg-4">
                        <span className="lineTitle">Where we work</span>
                        <h2 className="title">
                            <span className="ternary">Our top</span>
                             Products
                        </h2>
                    </div>
                    {products.map((product,i)=>(
                        <div data-aos="fade-up" className="partners_card col-md-6 col-lg-4" data-order={i+1}>
                            <Link to={`/product/${product.id}`}>
                                <div className="wrapper displayFlex justifyContentCenter "  style={{background:`url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/ProductsCovers%2F${product.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`,backgroundSize:"cover",color:"white",position:"relative",height:250}}>
                                    <div className="blackOpacity--products">
                                        <h3> {product.Title} </h3>
                                    </div>
                                </div>
                            </Link>
                        </div>
            
                    ))}
                    
                </div>
            </div>
        </section>
    )
    }
}

export default PartnerSection;