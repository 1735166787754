import {useState} from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../../ConfigurationSystem/firebase";

import SpinnerSvg from "../../../../ScreenLoader/screenLoader";

const InputSection = () => {

    const [name,setName]=useState("");
    const [phone,setPhone]=useState("");
    const [label,setLabel]=useState("");
    const [email,setEmail]=useState("");
    const [title,setTitle]=useState("");
    const [message,setMessage]=useState();
    const [successMessage,setSuccessMessage]=useState(false);
    const [loading,setLoading]=useState(false);

    const collectionRef = collection(db, "FAQs");

    const validateEmail=(email)=>{
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }

    const validatePhone=(phone)=>{
        const phoneRegex=/^\d{10}$/
        return phoneRegex.test(phone);
    }

    const addElement = async (e) => {
        e.preventDefault();
        if(!validateEmail(email)) setLabel("Enter valid email address");
        if(!validatePhone(phone)) setLabel("Invalid phone number entry");

        if(label=== ""){
            console.log("About to set");
            const docRef = doc(collectionRef);
            await setDoc(docRef, {name:name,phone:phone,email:email,title:title,message:message});
            await setName(" ");
            await setPhone(" ");
            await setEmail(" ");
            await setTitle(" ");
            await setMessage(" "); 
            await setLabel(" ");
            await setSuccessMessage(true);
            await setLoading(false);
        }
        
    } 
    
    if(loading) return <h5> Sending your message </h5>
    return (
        <>
            <div className="contactForm-header section_header">
                <span className="lineTitle">Contact us</span>
                <h2 className="title">
                    Do You Have any
                    <span className="ternary">Questions?</span>
                </h2>
            </div>
            <form
                action="#"
                className="contactForm-form contact-form displayFlex flex-wrap justifyContentBetween"
                method="POST"
                name="feedbackForm"
                data-type="feedback"
            >
                <input value={name}
                    className="contact-form_field contact-form_field--half field required"
                    name="feedbackName"
                    id="feedbackName"
                    type="text"
                    placeholder="Full name"
                    onChange={(e)=>setName(e.target.value)}
                />
                <input value={phone}
                    className="contact-form_field contact-form_field--half field required"
                    data-type="tel"
                    type="text"
                    name="feedbackTel"
                    id="feedbackTel"
                    placeholder="Phone"
                    onChange={(e)=>setPhone(e.target.value)}
                />
                <input value={email}
                    className="contact-form_field field required"
                    data-type="email"
                    type="text"
                    name="feedbackEmail"
                    id="feedbackEmail"
                    placeholder="Email Address"
                    onChange={(e)=>setEmail(e.target.value)}
                />
                <input value={title}
                    className="contact-form_field field required"
                    data-type="email"
                    type="text"
                    name="feedbackEmail"
                    id="feedbackEmail"
                    placeholder="Title"
                    onChange={(e)=>setTitle(e.target.value)}
                />
                <textarea value={message}
                    className="contact-form_field field required" 
                    data-type="message"
                    name="feedbackMessage"
                    id="feedbackMessage"
                    placeholder="Message"
                    onChange={(e)=>setMessage(e.target.value)}

                >
                </textarea>
                <label style={{color:"red",marginTop:30}}> {label} </label>
                {successMessage && <label style={{color:"green"}}> Your question has been submitted. </label> }
                <button type="submit" className="contact-form_btn btn" onClick={(e)=>{
                    setLoading(true);
                    addElement(e)
                }}>Send message</button>
            </form>
        </>
    )
}
export default InputSection;