import {useLocation} from "react-router-dom";

import "./screenLoader.css";

const SpinnerSvg = () => {
    const location=useLocation();
    return (
        <div className='spinnerFlexBox'>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><path fill="#001f3f" d="M19 19h20v20H19z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0s" calcMode="discrete"/></path><path fill="#001f3f" d="M40 19h20v20H40z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.125s" calcMode="discrete"/></path><path fill="#001f3f" d="M61 19h20v20H61z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.25s" calcMode="discrete"/></path><path fill="#001f3f" d="M19 40h20v20H19z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.875s" calcMode="discrete"/></path><path fill="#001f3f" d="M61 40h20v20H61z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.375s" calcMode="discrete"/></path><path fill="#001f3f" d="M19 61h20v20H19z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.75s" calcMode="discrete"/></path><path fill="#001f3f" d="M40 61h20v20H40z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.625s" calcMode="discrete"/></path><path fill="#001f3f" d="M61 61h20v20H61z"><animate attributeName="fill" values="#0da574;#001f3f;#001f3f" keyTimes="0;0.125;1" dur="1s" repeatCount="indefinite" begin="0.5s" calcMode="discrete"/></path></svg>
        </div>
    )
}
export default SpinnerSvg;