import { useRef , useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState} from "react";

import "./mainSlider.css";

const MainSlider = ({ data,currentContent,setCurrentContent }) => {
    const [initial,setInitial]=useState(true);
    const mainSliderRef=useRef(null);

    useEffect(()=>{
        mainSliderRef.current.style.backgroundSize="cover";
    },[mainSliderRef,currentContent])

    const stringCutter = (str) => {
        if (str) {
            const initial = str.split(" ");
            const mid = initial.slice(0, 20);
            const final = mid.join(" ");
            return final;
        }
    }

    return (
        <div ref={mainSliderRef} className="container-fluid demoSlider" style={{ background: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/ServicesCovers%2F${currentContent.data.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`}}>
            <div className="row purplishFade">
                <div className="container col-lg-6 col-md-6 col-12">
                    <div className="comn_slider-slide_content section_header">
                        <span className="lineTitle lineTitle--extended">Our services</span>
                        <h2 className="title">{currentContent.data.Title}</h2>
                        <p className="text">
                            {stringCutter(currentContent.data.MiniStart)}
                        </p>
                        <a className="btn" href={`/services/${currentContent.id}`}>Service details</a>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12" >
                    <div className="sliderPagination sliderPaginationContainer">
                        {data.map((ind, i) => (
                            <div className={(currentContent.id === ind.id || (initial && i === 0)) ? "circle selected-circle" : "circle"} onClick={() => {
                                setInitial(false);
                                setCurrentContent({data:data[i],id:data[i].id})
                            }}></div>
                        ))}
                    </div>
                </div>
                <div className="paginationContainer">
                    <Swiper spaceBetween={20} slidesPerView={3} loop={true}>

                        {data.map((ind, i) => (
                            <SwiperSlide>
                                <li className={(currentContent.data.Title === ind.Title) ? "comn_thumbs_thumb is-current" : "comn_thumbs_thumb"} style={{ background: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/ServicesCovers%2F${ind.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`,marginTop:100 , backgroundSize:"cover"}} onClick={() => {
                                    setInitial(false);
                                    setCurrentContent({data:data[i],id:data[i].id})
                                }}>

                                    <div className="comn_thumbs-thumb_inner displayFlex flex-column justify-content-end">
                                        <span className="overlay"></span>
                                        <h4 className="title">{ind.Title}</h4>
                                    </div>
                                </li>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
export default MainSlider;