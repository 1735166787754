import {Link} from "react-router-dom";
import { useContext} from "react";
import { useLocation } from "react-router-dom";
import toggleContext from "../../../Context/toggleContext";

import "./smlMenu.css";

const SmlMenu = () => {
    const context=useContext(toggleContext);
    
    const DropdownItem = ({ itemName, pathname }) => {
        const location = useLocation();
    
        if (!pathname) {
            return (
                <li className="nav-item">
                    <a className="nav-link">
                        <p> {itemName} </p>
                    </a>
                </li>
            )
        }
        return (
            <li className="nav-item">
                <Link to={pathname} className="nav-link">
                    {location.pathname === pathname ? (<p className="activeNavSml"> {itemName} </p>) : (<p> {itemName} </p>)}   </Link>
            </li>
        )
    }
    
    return (
        <ul className={`primary-bg smlNav ${context.isActive ? 'expanded' : 'compressed'}`}>
            <DropdownItem itemName="Home" pathname="/" />
            <DropdownItem itemName="About" pathname="/about" />
            <DropdownItem itemName="Services" pathname="/services" />
            <DropdownItem itemName="Projects" pathname="/projects" />
            <DropdownItem itemName="Blogs" pathname="/blogs" />
            <DropdownItem itesmName="Contacts" pathname="/contacts" />
            <DropdownItem itemName="Pages" />
            <DropdownItem itemName="FAQs" pathname="/faq" />
            <DropdownItem itemName="404 page" pathname="/error" />
        </ul>
    )
}
export default SmlMenu;