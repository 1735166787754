import MapSection from "../MapSection/mapSection";

import "./contactSection.css";

const ContactSection = () => {
    return ( 
        <section className="contact section">
            <div className="container">
                <div className="contact-wrapper col-lg-6 col-xxl-auto">
                    <div className="contact_header section_header">
                        <span className="lineTitle">Contact us</span>
                        <h2 className="title">
                            <span className="ternary">Contacts </span>
                            information
                        </h2>
                        <p className="text">
                            Our contact information are given below. Feel free to use any handles provided.
                        </p>
                    </div>
                    <ul className="contact-info">
                        <li className="contact-info_group">
                            <span className="name">Address</span>
                            <span className="content">Maharajgunj Rd, Kathmandu</span>
                        </li>
                        <li className="contact-info_group">
                            <span className="name">Email</span>
                            <span className="content d-inline-flex flex-column">
                                <a className="link" href="mailto:maildesignershub@gmail.com">maildesignershub@gmail.com</a>
                            </span>
                        </li>
                        <li className="contact-info_group">
                            <span className="name">Phone</span>
                            <span className="content d-inline-flex flex-column">
                                <a className="link" href="tel:986-2225988">986-2225988</a>
                            </span>
                        </li>
                    </ul>
                    <ul className="mediaLink displayFlex  justify-content-sm-start">
                        <li className="mediaLink_item">
                            <a className="mediaLink_item-link" href="https://www.facebook.com/profile.php?id=100091539545793" target="_blank" rel="noopener noreferrer">
                                <i className="icon-facebook"></i>
                            </a>
                        </li>
                        <li className="mediaLink_item">
                            <a className="mediaLink_item-link" href="https://www.facebook.com/profile.php?id=100091539545793" target="_blank" rel="noopener noreferrer">
                                <i className="icon-whatsapp"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="contact_map">
                    <MapSection/>
                </div>
            </div>
        </section>
    )
}
export default ContactSection;