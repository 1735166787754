import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged , getAuth , sendPasswordResetEmail } from "firebase/auth";


import toggleContext from "../CommonComponents/Context/toggleContext";
import SpinnerSvg from "../ScreenLoader/screenLoader";

import { signInWithEmailAndPassword } from "firebase/auth";


import "./login.css";


const Login = ({ setLoggedIn }) => {

    const context = useContext(toggleContext);
    const navigate=useNavigate();
    const auth=getAuth();

    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState(" ");
    const [password, setPassword] = useState(" ");
    const [error, setError] = useState("");
    const [label,setLabel]=useState({data:"",isPositive:false});

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) { 
                await context.setCurrentUser(user.email);
                if (!context.signOut) {
                    setLoggedIn(true);
                    navigate("/dashboard/blogs");
                }
                else setLoading(false);
            }
            else {
                setLoading(false);
                setLoggedIn(false);
            }

        })
    }, [navigate])

    const loginVerifier = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password).then((userCredentials) => {
            context.setCurrentUser(userCredentials.user.email);
            context.setSignOut(false);
            setLoggedIn(true);
            navigate("/dashboard/blogs");
        })
        .catch((error) => {
             setError(error.message);
            setLabel({data:"",isPositive:false});
        })
    }

    const forgetPasswordHandler = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setLabel({data: "Password reset link sent" , isPositive: true});
                setError("");
                
            })
            .catch((error) => {
                setLabel({data: error.message , isPositive:false});
                setError("");
            });
    }

    if (loading) return <SpinnerSvg />

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 formContainer">
                        <form>
                            <img src={require("../../Images/Designers Hub Logo.png")} className="mainLogo" />

                            <h2> Access  Your <b> Dashboard </b> </h2>
                            <label> Username : </label><br />
                            <input type="text" placeholder="your-email@gmail.com" onChange={(e) => setEmail(e.target.value)} /><br />
                            <label> Password : </label><br />
                            <input type="password" placeholder="Your password" onChange={(e) => setPassword(e.target.value)} /><br />
                            <div className="checkBoxContainer">
                                <span className="relative"> <input type="checkbox" /> <label> Remember Me</label> </span>

                                <p className="underlineText" onClick={()=>forgetPasswordHandler()} style={{cursor:"pointer"}}> Forgot password? </p>
                            </div>
                            {(label.data !== "") && <p style={label.isPositive?{color:"green"}:{color:"red"}}> {label.data} </p>}
                            {error!=="" && <p style={{ color: "red" }}> {error} </p>}
                            <button className="btn btn-primary" onClick={(e) => {
                                setLabel({data: "",isPositive:false})
                                loginVerifier(e);

                            }
                            }> Login </button>

                            <div className="poweredByContainer">

                                <h5 className="lightText relative">Powered by:</h5>

                                <img src={require("../../Images/silicontech-transparent.png")} alt="Silicon Tech Logo" className="stLogo" />

                            </div>
                        </form>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 image">

                    </div>
                </div>
            </div>
        )
    }
export default Login;