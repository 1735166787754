
import { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
import "./contactInformation.css";



const ContactInformation = () => {
    useEffect(()=>{
        Aos.init({duration:800,once:true})
    },[]);
        
    return (
        <section class="info section-nopb">
            <div class="container">
                <div class="info_data row g-0">
                    <div class="info_header section_header col-md-6 col-xxl-3" data-order="1">
                        <span class="lineTitle" data-aos="fade-right">Contact us</span>
                        <h2 class="title" data-aos="fade-right" data-aos-delay="30">
                            <span class="ternary">Contacts </span>
                            Information
                        </h2>
                    </div>
                    <div class="info_data-card col-md-6 col-xxl-3" data-aos="fade-left" data-order="1">
                        <div class="wrapper displayFlex flex-column justifyContentBetween">
                            <i class="icon-location icon"></i>
                            <h4 class="title">Address</h4>
                            <span class="content"> Maharajgunj Rd, Kathmandu </span>
                        </div>
                    </div>
                    <div class="info_data-card col-md-6 col-xxl-3" data-aos="fade-left" data-aos-delay="20" data-order="2">
                        <div class="wrapper displayFlex flex-column justifyContentBetween">
                            <i class="icon-inbox icon"></i>
                            <h4 class="title">Email</h4>
                            <span class="content displayFlex flex-column">
                                <a href="mailto:maildesignershub@gmail.com" class="link">maildesignershub@gmail.com</a>
                            </span>
                        </div>
                    </div>
                    <div class="info_data-card col-md-6 col-xxl-3" data-aos="fade-left" data-aos-delay="40" data-order="3">
                        <div class="wrapper displayFlex flex-column justifyContentBetween">
                            <i class="icon-call icon"></i>
                            <h4 class="title">Phone</h4>
                            <span class="content displayFlex flex-column">
                                <a href="tel:+13136453395" class="link">986-2225988</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="info_map">
                    <div id="map"></div>
                </div>
            </div>
        </section>
    )
}
export default ContactInformation;