import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore";
import {getAuth} from "firebase/auth";

import { getStorage } from "firebase/storage";


//Dotenv not working at the moment, to test need to enter the keys manually
const firebaseConfig={
  
  apiKey: "AIzaSyAC4lUyMeIVD8fNM77q6NiCP6EgWSFxOoY",

  authDomain: "designers-hubs.firebaseapp.com",

  projectId: "designers-hubs",

  storageBucket: "designers-hubs.appspot.com",

  messagingSenderId: "882161068766",

  appId: "1:882161068766:web:eb5eb6ecf6de39329b304f",

  measurementId: "G-MRXYSFC4J2"

}

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

export const db = getFirestore(app);

export const auth=getAuth(app);