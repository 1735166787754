import React from 'react';

import { useState , useContext } from 'react';

import { Link, useLocation } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";

import toggleContext from '../Context/toggleContext';

import BrandTitle from './HeaderComponent/brandTitle';

import "../commonCSS.css";
import "../containerProps.css";
import "../flexBoxProps.css";
import "../alignments.css";
import "../iconAndFonts.css";
import "./header.css";
import SmlMenu from './HeaderComponent/SmallMenu/smlMenu';



const Header = () => {
    const context=useContext(toggleContext);
    const [pageHovered,setPageHovered]=useState(false);
    
    let navBarClass = "";
    let smallNavClass = "";
    let scrollRef={};

    const location = useLocation();


    const [scrollValue, setScrollValue] = useState({value : 0});



    window.addEventListener("scroll", () => setScrollValue({value: window.scrollY}));

    if (scrollValue.value > 10 || context.isActive) {
        navBarClass = "header_navbar goTop primary-bg-force";
        smallNavClass = "smallNav primary-bg-force"
    }
    else {
        navBarClass = "header_navbar";
        smallNavClass = "smallNav"
    }



    return (
        <header className="header primary" data-page="faq">
            <div className={navBarClass}>
                <div className="container displayFlex flex-wrap flex-lg-nowrap  justifyContentBetween" style={{alignItems:"center"}}>
                    <BrandTitle />
 
                    <ul className="nav-bar largeNav">
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                {location.pathname === "/" ? (<p className="activeNav"> Home </p>) : (<p> Home </p>)}   </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about" className="nav-link">
                                {location.pathname === "/about" ? (<p className="activeNav"> About</p>) : (<p> About </p>)}   </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/services" className="nav-link">
                                {location.pathname === "/services" ? (<p className="activeNav"> Services </p>) : (<p> Services </p>)}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/projects" className="nav-link">
                                {location.pathname === "/projects" ? (<p className="activeNav"> Projects</p>) : (<p> Projects </p>)}
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/blogs" className="nav-link">
                                {location.pathname === "/blogs" ? (<p className="activeNav"> Blogs</p>) : (<p> Blogs </p>)}
                            </Link>
                        </li>
                       
                        <li className="nav-item">
                            <Link to="/faq" className="nav-link">
                                {location.pathname === "/faq" ? (<p className="activeNavSml"> FAQs</p>) : (<p> FAQs </p>)}
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/contacts" className="nav-link">
                                {location.pathname === "/contacts" ? (<p className="activeNav"> Contacts</p>) : (<p> Contacts </p>)}
                            </Link>

                        </li>
                       
                    </ul>
                   
                </div>
            </div>


            <div className={smallNavClass}>
                <div className="displayFlex">
                    <BrandTitle />
                    <div className="textDiv">
                            <HiOutlineBars3BottomRight className="navBarIcon" onClick={async () => {
                                if(context.isActive){
                                    await context.setIsActive(false);
                                    console.log(scrollRef);
                                }
                                else{
                                    await context.setIsActive(true);
                                    scrollRef={...scrollValue};
                                    console.log(scrollRef);
                                }
                            }

                            } />

                    </div>

                </div>

            </div>
            <SmlMenu/>
            
            
        </header>
    )
}


export default Header;
