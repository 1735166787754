import "./dataDisplayer.css";

const DataDisplayer = ({setFaqAnswer,setFaqName,setFaqEmail,setFaqMessage, setFaqPhone, setFaqTitle,setCurrentChangesArray,setImageArrayChanged,setUpdateImage,setUpdateArray,setSelectedImageArray,setImageChanged, setSelectedImageArrayName, setTotalImageInputs,data, setNewPrice,setOldPrice,setTotalStars,selectedId, setSelectedId, setSelectedImage, setSelectedTitle, setSelectedArticle, setPlusClicked, setSelectedInitialPara, collectionName }) => {

     return (
            <>
                {(data.length > 0) && data.map(index => (

                    <div key={index.id} className="container-dashboard" style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${index.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)` }} onClick={() => {
                        setPlusClicked(false);
                        
                        setSelectedId(index.id);
                        if(collectionName === "FAQs"){
                            setFaqName(index.name);
                            setFaqEmail(index.email);
                            setFaqMessage(index.message);
                            setFaqPhone(index.phone);
                            setFaqTitle(index.title);
                            setFaqAnswer(index.answer ? index.answer : " ");
                        }
                        else{
                        setSelectedTitle(index.Title);
                        setSelectedImage(index.Image);
                        setSelectedArticle(index.Article);
                        setSelectedInitialPara(index.MiniStart);
                        setImageChanged(false);
                        if(collectionName === "Products"){
                            setNewPrice(index.NewPrice);
                            setOldPrice(index.OldPrice);
                            setTotalStars(index.TotalStars);
                            setSelectedImageArrayName(index.ImageArray);
                            setTotalImageInputs(index.TotalImageInputs);
                            setSelectedImageArray([]);
                            setUpdateImage(false);
                            setUpdateArray(false);
                            setImageArrayChanged([]);
                            setCurrentChangesArray([]);
                        }
                    }
                        
                    }}>
                        <div className={`lightFade-dashboard ${selectedId === index.id ? 'selected' : 'unselected'}`}>
                            {collectionName !== "FAQs" ? <h5> {index.Title} </h5> : <h5> {index.name} </h5>}
                            {collectionName !== "FAQs" ? (<p className="dateDisplay-dashboard"> {(index.createdAt) && index.createdAt.toDate().toString()} </p>):(<p className="dateDisplay-dashboard"> {index.email} </p>)}
                        </div>
                    </div>
                ))}
            </>
        )
}
export default DataDisplayer;