import { useEffect } from "react";

import parse from "html-react-parser";

const SingleServiceContent = ({data,collectionName}) => {
    return (
        <section className="tabs section">
            <div className="container"> 
                <div className="tabs_header section_header displayFlex flex-wrap flex-lg-nowrap align-items-lg-end justify-content-xl-between">
                    <div className="tabs_header-wrapper">
                      
                        <h2 className="title" data-aos="fade-right">
                            We Provide
                            <span className="ternary">{data.Title}</span>
                            Services
                        </h2>
                    </div>
                    <p className="text" data-aos="fade-left">
                        {data.MiniStart}
                    </p>
                </div>
                <div className="tabs_services row">
                    <div className="tabs_services-content" style={{padding:0}}>
                        
                        <div className="content active" id="02">
                            <div className="img-wrapper">
                                <picture>
                                    <source
                                        data-srcset={`https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${data.Image}?alt=media&token=77c204b5-0ff2-429b-bce0-2b11137d926e`}
                                        srcset={`https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${data.Image}?alt=media&token=77c204b5-0ff2-429b-bce0-2b11137d926e`}
                                        type="image/webp"
                                    />
                                    <img
                                        className="lazy"
                                        data-src={`https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${data.Image}?alt=media&token=77c204b5-0ff2-429b-bce0-2b11137d926e`}
                                        src={`https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/${collectionName}Covers%2F${data.Image}?alt=media&token=77c204b5-0ff2-429b-bce0-2b11137d926e`}
                                        alt="media"
                                    />
                                </picture>
                            </div>
                            <div className="text-wrapper displayFlex flex-column">                
                                <div className="description">
                                    <p className="text">
                                        {(data.Article) && parse(data.Article)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SingleServiceContent;