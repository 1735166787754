import "./aboutMini.css";

const AboutMini=()=>{

    return(
        <section className="comn aboutMini section">
                <div className="container displayFlex flexXlNoWrap alignItemsXlCenter justifyContentBetween">
                    <div className="comn_header section_header col-lg-6 col-xl-auto">
                        <span className="lineTitle" data-aos="fade-down">Who we are</span>
                        <h2 className="title" data-aos="fade-right">
                            Bringing
                            <span className="ternary">Your Ideas</span>
                            and Innovations to Life
                        </h2>
                        <p className="text" data-aos="fade-up" data-aos-delay="20">
                            Our designing and structural team is committed to providing sustainable, creative & efficient engineering solutions
                            for our communities
                        </p>
                        <ul className="comn_header-list">
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="30">
                                <i className="icon-arrow_right icon"></i>
                                Making lives easier
                            </li>
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="30">
                                <i className="icon-arrow_right icon"></i>
                                Get every solution right here
                            </li>
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="40">
                                <i className="icon-arrow_right icon"></i>
                                Innovation and creativity
                            </li>
                            <li className="comn_header-list_item displayFlex " data-aos="fade-up" data-aos-delay="40">
                                <i className="icon-arrow_right icon"></i>
                                Fine engineering only with us
                            </li>
                        </ul>
                        <a className="btn" href="/faq" data-aos="fade-up" style={{transition:".25s ease-in-out"}}>Consult now</a>
                    </div>
                    <div className="comn_img" data-aos="zoom-in" data-aos-duration="700">
                        <img src={require("../../../../Images/startup-594090_1280.jpg")} className="img-fluid"/>
                    </div>
                </div>
            </section>
    )
}
export default AboutMini;