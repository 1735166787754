import "./cover.css";
const Cover = ({ title }) => {
    return (
        <div className="header_extension primary-bg">
            <div className="container">
                <div className="section_header">
                <li className="breadcrumbs_item breadcrumbs_item--current">
                    <span className="lineTitle lineTitle--extended">{title}</span>
                  </li>  
                </div>
            </div>
            <div className="imgContainer">
                <img src="https://html.merku.love/axial/img/plan.webp" className="designImage" />
            </div>
        </div>
    )
}
export default Cover;