import { useEffect } from "react";

import Header from "../CommonComponents/Header/header";
import Footer from "../CommonComponents/Footer/footer";
import Cover from "../CommonComponents/Cover/cover";
import ImageDataFetcher from "../CommonComponents/ImageDataFetcher/imageDataFetcher";
import Aos from "aos";

import "./projects.css";
import "aos/dist/aos.css";

const Projects = () => {
    useEffect(() => {
        window.scroll({top:0,behavior:"instant"});
        Aos.init({ duration: 800, once: true })
    }, []);

    return (
        <>
            <Header />
            <Cover title={"Our Projects"}/>
            <main className="projects section">
                <div className="container">
                    <ul className="projects_list row g-0">
                        <ImageDataFetcher contentType={0} collectionName={"Projects"}/>
                    </ul>
                </div>
            </main>
            <Footer />
        </>
    )
}
export default Projects;