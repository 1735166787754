import {GoTriangleRight} from "react-icons/go";
import { Link } from "react-router-dom";

const BlogsList = ({data}) => {

    const dateJoiner=(dateString)=>{
        const splitted=dateString.split(" ");
        const date=splitted.slice(0,3);
        const finalValue=date.join(" ");
        return finalValue;
    }

    return (
        <>
            {data.map(blogs => (

                <div key={blogs.id} className="col-lg-6 col-md-6 col-12 blogContainer-child">
                    <img src={`https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/BlogsCovers%2F${blogs.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`} className="blog-view-image"/>
                    <p className="blogDate"><GoTriangleRight className="text-warning" /> {dateJoiner(blogs.createdAt.toDate().toString())} </p>
                    <div className="blogTitle">
                        <h3 className="blogTitle"> {blogs.Title} </h3>
                    </div>
                    <div className="articleContainer-blogs">
                        <p className="blogArticle"> {blogs.Article} </p>
                    </div>
                    <hr className="horizontalLine" />
                    <Link to={`/blogs/${blogs.id}`}>
                        <p className="link link-arrow">
                            Read now
                            <i className="icon-arrow_right icon"></i>
                        </p>
                    </Link>
                </div>
            ))}
        </>
    )
}
export default BlogsList;