import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../ConfigurationSystem/firebase";

import parse from "html-react-parser";
import SpinnerSvg from "../../ScreenLoader/screenLoader";



import "./formatDisplayer.css";

const FormatDisplayer = ({ product , docId }) => {
    const [loading, setLoading] = useState(true);
    const [mainOnDisplay, setMainOnDisplay] = useState("");
    const [allProducts,setAllProducts]=useState([]);
    const liRef = useRef(null);
    const navigate = useNavigate();

    const productCollectionRef=collection(db,"Products");

    useEffect(() => {
        if (product) {
            setMainOnDisplay(product.Image);
        }
    }, [product])

    useEffect(()=>{
        const getData=async ()=>{
            const data = await getDocs(productCollectionRef);
            await setAllProducts(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            setLoading(false);
        }
        getData();
    },[])

    useEffect(() => {
        if (liRef.current !== null) liRef.current.style.backgroundSize = "cover";
    }, [mainOnDisplay])

    return (
        <div className="formatDisplayer--products">
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="main-img">
                            <img className="main-img" src={`https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/ProductsCovers%2F${mainOnDisplay}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23`} alt="ProductS" />
                            <div className="row my-3 previews">
                                <Swiper spaceBetween={20} slidesPerView={3} loop={true}>

                                    {(product.ImageArray) && product.ImageArray.map((ind, i) => (
                                        <SwiperSlide>
                                            <li ref={liRef} style={{ background: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/ProductsCovers%2F${ind}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`, backgroundSize: "cover", height:150}} onClick={() => {
                                                const mainImg = mainOnDisplay;
                                                setMainOnDisplay(ind);
                                                product.ImageArray[i] = mainImg;
                                            }}>

                                                <div className="comn_thumbs-thumb_inner displayFlex flex-column justify-content-end">
                                                    <span className="overlay"></span>
                                                </div>
                                            </li>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 detailsContainer" style={{ marginBottom: 50, paddingLeft: 20 }}>
                        <div className="main-description px-2">
                            <div className="category text-bold">
                                Category: {product.Title}
                            </div>
                            <div className="product-title text-bold my-3">
                                {product.Title}
                            </div>


                            <div className="buttons d-flex my-5">
                                <div className="block">
                                    <button className="btn btn-success" onClick={() => navigate("/contacts")}> Contact us </button>
                                </div>
                            </div>




                        </div>

                        

                        <div className="row questions bg-light p-3">
                            <div className="col-md-1 icon">
                                <i className="fa-brands fa-rocketchat questions-icon"></i>
                            </div>

                        </div>





                    </div>
                    <div className="row product-details-container">
                        <div className="product-details col-12">
                            <p className="details-title text-color mb-1">Product Details</p>
                            <p className="description">{product.MiniStart} </p>
                        </div>
                        <div className="col-12 text">
                            {product.Article && parse(product.Article)}

                        </div>
                    </div>
                </div>
            </div>



            <div className="container similar-products my-4">
                <hr style={{ marginBottom: 50 }} />

                {loading ? <SpinnerSvg /> : (
                    <div className="row">
                        <Swiper spaceBetween={20} slidesPerView={3} loop={true}>
                            {allProducts.map(p => p.id !== docId && (
                                
                                <>
                                    <SwiperSlide>
                                        <li style={{ background: `url(https://firebasestorage.googleapis.com/v0/b/designers-hubs.appspot.com/o/ProductsCovers%2F${p.Image}?alt=media&token=377457bb-1e14-4247-a735-f419a1f19a23)`, backgroundSize: "cover" }}>
                                            <a href={`/product/${p.id}`}>
                                                <div className="comn_thumbs-thumb_inner displayFlex flex-column justify-content-end">
                                                    <span className="overlay"></span>
                                                </div>
                                            </a>
                                        </li>
                                    </SwiperSlide>
                                </>
                            ))}
                        </Swiper>
                    </div>)}
            </div>

        </div>



    )
}

export default FormatDisplayer;