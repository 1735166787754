import React from "react";
import {FiMap} from "react-icons/fi";
import {FaPhoneVolume} from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    // <footer className="footer primary-bg">
    //   <div className="container">
    //     <div className="row">
    //       <div className="footer_main-block footer_title_block col-lg-4 col-12">
    //         <img
    //           src={require("../../../Images/dhl.png")}
    //           style={{ width: "200px", height: "120px", marginLeft: 10, marginRight: 10 }}
    //           alt="Designers Hub Logo"
    //         />
    //         <div className="followContainer--footer">
    //           <h5 style={{ marginTop: 30 }}> Follow us on : </h5>

    //           <div className="displayFlex iconContainer--footer">

    //             <li className="socials_item">
    //               <a className="socials_item-link" href="https://www.facebook.com/profile.php?id=100091539545793" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
    //                 <i className="icon-facebook"></i>
    //               </a>
    //             </li>
    //             <li className="socials_item">
    //               <a className="socials_item-link" href="#" target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
    //                 <i className="icon-whatsapp"></i>
    //               </a>
    //             </li>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="footer_main-block containerContacts-footer col-12 col-lg-4">
    //         <div className="iconCollections-contacts">
    //           <div className="group-wrapper">
    //             <a href="tel:+9862225988">
    //               <MdPhoneAndroid className="icon-call icon callIcon" style={{marginTop:18}}/>
    //             </a>
    //             <div>
    //             <span className="contact-info callContact" style={{marginLeft:50}}> +9862225988 </span><br/>
    //             <span className="contact-info callContact" style={{marginLeft:50}}> Give us a call </span>
    //             </div>
    //           </div>

    //           <div className="group-wrapper showInSmall" style={{paddingTop:30,paddingLeft:50}}>
    //             <a href="https://www.google.com/maps/search/?api=1&query=27.738135,85.333133">
    //               <i className="icon-location icon locationIcon"></i>
    //             </a>
    //             <span className="contact-info locationContact" style={{ marginLeft: 40, marginTop: 20}}>Maharajgunj, Kathmandu</span>
    //           </div>

    //         <div className="group-wrapper showInSmall" style={{marginTop:20}}>
    //           <a href="mailto:maildesignershub@gmail.com">
    //             <AiOutlineMail className="icon mailIcon" />
    //             <span className="contact-info" style={{marginLeft:130,marginTop:20,color:"white"}}>maildesignershub@gmail.com</span>
    //           </a>
    //         </div>



    //           <h4 className="usefulLinks--footer"> Useful links </h4>

    //           <ul className="footer_main-block_nav">
    //             <div className="displayFlex">
    //               <div>
    //                 <li className="list-item">
    //                   <a className="link d-inline-flex" href="/about">
    //                     About
    //                   </a>
    //                 </li>
    //                 <li className="list-item">
    //                   <a className="link d-inline-flex" href="/services">
    //                     FAQs
    //                   </a>
    //                 </li>
    //                 <li className="list-item">
    //                   <a className="link d-inline-flex" href="/blogs">
    //                     Contacts
    //                   </a>
    //                 </li>
    //               </div>
    //               <div className="secondFlexRow">
    //                 <li className="list-item">
    //                   <a className="link d-inline-flex" href="/projects">
    //                     Projects
    //                   </a>
    //                 </li>
    //                 <li className="list-item">
    //                   <a className="link d-inline-flex" href="/blogs">
    //                     Blogs
    //                   </a>
    //                 </li>
    //                 <li className="list-item">
    //                   <a className="link d-inline-flex" href="/blogs">
    //                     Services
    //                   </a>
    //                 </li>
    //               </div>
    //             </div>
    //           </ul>
    //         </div>
    //       </div>

    //       <div className="footer_main-block lastColumn--footer col-12 col-lg-4">
    //         <div className="group-wrapper showInBig">
    //           <a href="https://www.google.com/maps/search/?api=1&query=27.738135,85.333133">
    //             <i className="icon-location icon"></i>
    //           </a>
    //           <span className="contact-info" style={{marginLeft:50,marginTop:20}}>Maharajgunj, Kathmandu</span>
    //         </div>

    //         <div className="group-wrapper showInBig" style={{marginTop:20}}>
    //           <a href="mailto:maildesignershub@gmail.com">
    //             <AiOutlineMail className="icon" />
    //             <span className="contact-info" style={{marginLeft:50,marginTop:20}}>maildesignershub@gmail.com</span>
    //           </a>
    //         </div>

            

    //       </div>

    //       </div>

    //     </div>

        
    //   </div>
    //   <div className="copyrightContainer">
    //       <h5> Copyright © 2019, All Right Reserved </h5>
    //     </div>
    // </footer>

    <footer id="dk-footer" className="dk-footer primary-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="dk-footer-box-info">
              <a href="index.html" className="footer-logo">
              <img
               src={require("../../../Images/dhl.png")}
               style={{ width: "200px", height: "120px", marginLeft: 10, marginRight: 10 }}
               alt="Designers Hub Logo"
              />
              </a>
              <p className="footer-info-text">
                Transfer your house into a dream home with our expert design and services.
              </p>
              <div className="footer-social-link">
                <h3>Follow us</h3>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=100091539545793">
                      <i className="icon-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="contact-us">
                  <div className="contact-icon">
                    <a href="https://www.google.com/maps/search/?api=1&query=27.738135,85.333133"><FiMap className="icon"/></a>
                  </div>
                  <div className="contact-info">
                    <h3>Kathmandu Nepal</h3>
                    <p>Maharajgunj</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="contact-us contact-us-last">
                  <div className="contact-icon">
                  <a href="tel:+9862225988"><FaPhoneVolume className="icon"/></a>
                  </div>
                  <div className="contact-info">
                    <h3>98 62 225 988</h3>
                    <p>Give us a call</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="footer-widget footer-left-widget">
                  <div className="section-heading">
                    <h3>Useful Links</h3>
                    <span className="animate-border border-black"></span>
                  </div>
                  <ul>
                    <li>
                      <a href="/about">About us</a>
                    </li>
                    <li>
                      <a href="/services">Services</a>
                    </li>
                    <li>
                      <a href="/projects">Projects</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="/contacts">Contact us</a>
                    </li>
                    <li>
                      <a href="/blogs">Blog</a>
                    </li>
                    <li>
                      <a href="/faq">Faqs</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="footer-widget">
                  <div className="section-heading">
                    <h3>Powered By </h3>
                    <span className="animate-border border-black"></span>
                    <div className="poweredByContainer container">
                      <div className="poweredByContent">
                        <a href="https://silicontechnepal.com.np" target="_blank" rel="noopener noreferrer">
                          <img
                            src={require("../../../Images/silicontech-transparent.png")}
                            style={{ width: 250, marginLeft:-60}}
                            alt="Powered by SiliconTech"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-12" style={{textAlign:"center"}}>
              <span>Copyright © 2019, All Right Reserved Seobin</span>
            </div>
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
