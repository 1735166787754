import "./skillsSection.css";

import ProgressBarCollection from "./SkillsSectionComponents/progressBarCollection";

const SkillsSection = () => {
    return (
        <div className="container skillsContainer">
            <div className="row">
                <div className="col-lg-6 col-12 imgContainer-skills">
                    <div className="skills_img" data-aos="zoom-in" data-aos-duration="600">
                        <picture>
                            <source
                                data-srcset="https://html.merku.love/axial/img/about/03.webp"
                                srcset="https://html.merku.love/axial/img/about/03.webp"
                                type="image/webp"
                            />
                            <img
                                className="skills_img-img"
                                data-src="https://html.merku.love/axial/img/about/03.webp"
                                src="https://html.merku.love/axial/img/about/03.webp"
                                alt="media"
                            />
                        </picture>
                    </div>
                </div>
                <div className="col-lg-6 col-12 wrapper wrapper--skills">
                    <div className="skills_header section_header">
                        <span className="lineTitle" data-aos="fade-down">We build only quality things</span>
                        <h2 className="title" data-aos="fade-right">
                            Trusted Quality for Over
                            <span className="ternary">20 Years</span>
                        </h2>
                        <p className="text" data-aos="fade-up" data-aos-delay="50">
                        When it comes to interior design, experience matters. For over 20 years, we have been the trusted name in delivering exceptional quality to our valued clients.
                        </p>
                        <ProgressBarCollection/>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default SkillsSection;