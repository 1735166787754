import {useState,useEffect} from "react";
import { collection, getDocs } from "firebase/firestore";

import {db} from "../../../ConfigurationSystem/firebase";
import SpinnerSvg from "../../../ScreenLoader/screenLoader";

import FourStats from "../../../CommonComponents/FourStats/fourStats";
import "./numbersContainer.css";

const NumbersContainer = () => {
    const [system,setSystem]=useState([]);
    const [loading,setLoading]=useState(true);

    const systemCollectionRef=collection(db,"System");
    
    useEffect(() => {
        const getData = async () => {
            const data = await getDocs(systemCollectionRef);
            await setSystem(data.docs.map((doc, i) => ({ ...doc.data(), id: doc.id })));
            await setLoading(false);
        }
        getData();
    }, [])

    if(loading) return <SpinnerSvg/>
    
    return (
        <section className="numbers primary-bg section">
            <div className="container">
                <div className="row g-3 g-lg-4 justifyContentBetween align-items-end">
                    <div className="numbers_header section_header col-lg-6 col-xl-5">
                        <span className="lineTitle">What we do</span>
                        <h2 className="title">
                            Building The Future on a
                            <span className="ternary">Foundation of Excellence</span>
                        </h2>
                        <p className="text">
                        From residential to commercial projects, we are committed to delivering excellence in every detail. Our meticulous attention to color, texture, and layout ensures an aesthetic masterpiece that reflects your personality and leaves a lasting impression.
                        </p>
                    </div>
                    <div className="col-lg-6 col-xl-5 fourStatsContainer">

                    <FourStats fourStatsClass={"advantages_header-list-no_color"} system={system}/>
                    </div>
                    
                    <img src={require("../../../../Images/job-5382501_1920.jpg")} className="img-fluid numbersContainerImage" data-aos="zoom-in" data-aos-duration="600" data-aos-once="true"/>
                </div>
            </div>
        </section>
    )
}
export default NumbersContainer;